import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Loader from 'react-loaders';

import './index.css';

import { ScreenInfoProvider } from './context/ScreenInfoContext';
import { AccountProvider } from './context/AccountContext';
import { OrdersProvider } from './context/OrdersContext';

import supabase from './lib/supabase';
import NotificationScreen from './pages/notification';

const Login = lazy(() => import('./public/login'));
const SignedInScreen = lazy(() => import('./pages/signed-in'));
const PickupScreen = lazy(() => import('./pages/pickup/index'));
const BoxPickupScreen = lazy(() => import('./pages/box_pickup/index'));

const RedirectToLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // Check if the user is trying to access a URL other than login/register
    if (location.pathname !== "/login" && location.pathname !== "/register" && location.pathname !== "/forgot_password") {
      localStorage.setItem('preLoginPath', location.pathname + location.search);
    }
    navigate('/login', { replace: true });
  }, [navigate, location]);

  // Optionally, return a loading indicator or null while redirecting
  return null;
};

function App() {
  const [session, setSession] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      //console.log(session);
      setSession(session);
      setAuthLoading(false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      //console.log(session);
      setSession(session);
      setAuthLoading(false);
    });

    return () => {
      if (authListener) {
        authListener?.subscription?.unsubscribe();
      }
    }
  }, []);

  const Logout = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
      const handleLogout = async () => {
        supabase.auth.getSession().then(async ({ data }) => {
          //console.log("Session before logout",data);
          const { error } = await supabase.auth.signOut({scope:'local'});
          if (!error) {
            setSession(null);
            navigate('/login', { replace: true });
          } else {
            console.error(error);
          }
        });
      };
  
      handleLogout();
    }, [navigate]);
  
    return null; // or a loader or some other indicator that logout is in progress
  };

  if (authLoading) {
    return <Loader type="ball-pulse-rise" />;  // Show loading animation while checking auth
  } else {
    return (
      <Router>
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise" />
                </div>
                <h6 className="mt-5">Loading...</h6>
              </div>
            </div>
          }
        >
            {session && session.user ? (
              <AccountProvider>
                <ScreenInfoProvider>
                  <OrdersProvider>
                    <Routes>
                      <Route exact path="/signed-in" element={<SignedInScreen />} />
                      <Route exact path="/pickup" element={<PickupScreen />} />
                      <Route exact path="/notification" element={<NotificationScreen />} />
                      <Route exact path="/box-pickup" element={<BoxPickupScreen />} />
                      <Route exact path="/logout" element={<Logout />} />
                      <Route path="/*" element={<Navigate to="/pickup" replace />} />
                    </Routes>
                  </OrdersProvider>
                </ScreenInfoProvider>
              </AccountProvider>
            ) : (
              <Routes>
                <Route exact path="/signed-in" element={<SignedInScreen />} />
                <Route exact path="/login" element={<Login />} />
                { session !== false && <Route path="/*" element={<RedirectToLogin />} /> }
              </Routes>
            )}
        </Suspense>
      </Router>
    );
}
}

export default App;
